import React from 'react';
import { useSelector, selectors } from 'azgaz-connect';
import { ExtraCarsList } from '@perxteam/azgaz-ui';

import CarsItem from '@/apps/appCreator/components/CarsItem';

const CarsRelated = () => {
  const carsRelated = useSelector(selectors.getCarsRelated);

  return (
    <ExtraCarsList
      style={{
        backgroundColor: '#f2f0f0',
      }}
      title="Вас могут заинтересовать"
      Item={CarsItem}
      cars={carsRelated}
    />
  );
};

export default CarsRelated;
