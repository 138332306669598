import React, { FC, useEffect } from 'react';
import '@perxteam/azgaz-ui/ui/lib/style.css';

import '@/assets/sass/main.scss';
import { config } from '@/utils';

// Для работы popover нужен глобальнй файл стилей antd, но эти тили конфликтуют со стилями /order
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved, import/extensions
import antdStyles from '!style-loader?injectType=lazySingletonStyleTag!css-loader!antd/lib/style/index.css';

if (config.get('environment') === 'local') {
  // @ts-ignore
  import('@/assets/sass/_fonts.scss');
}

const LayoutMain: FC = ({ children }) => {
  useEffect(() => {
    antdStyles.use();
    return () => antdStyles.unuse();
  });

  return <div className="csp-widget">{children}</div>;
};

export default LayoutMain;
