import { configureStore } from '@reduxjs/toolkit';
import book from './book';
import discounts from './discounts';
import carModels from './carModels';
import dealerOffices from './dealerOffices';

const store = configureStore({
  reducer: {
    book: book.reducer,
    discounts: discounts.reducer,
    carModels: carModels.reducer,
    dealerOffices: dealerOffices.reducer,
  },
});

export type Store = typeof store;
export type GetState = typeof store.getState;
export type State = ReturnType<GetState>;

export default store;
