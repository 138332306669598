import React, { FC, useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useStore } from 'react-redux';

import {
  CarCard,
  CarGallery,
  CarOptions,
  CarComment,
  CarFooter,
  CarInfo,
  CarInfoFinance,
  CarInfoProperties,
} from '@perxteam/azgaz-ui';

import {
  useDispatch,
  selectors,
  actions,
  useSelector as useSelectorConnect,
} from 'azgaz-connect';
import { useCarModels, useDealerOffices } from '@/hooks';
import { config } from '@/utils';
import { State } from '../../store';
import {
  getCarPriceWithDiscounts,
  getCarPriceInfo,
  getCarModelImage,
} from '../../selectors';
import { CarContext } from '../../contexts';
import outsideComponents from '../../outsideComponents';
import CarBackButton from '../CarBackButton';
import CarsSeen from '../CarsSeen';
import CarsRelated from '../CarsRelated';
import CarBar from '../CarBar';
import CarForms from '../CarForms';
import FavouriteBtn from '../CarsItemFavouriteBtn';
import FavouritesPopover from '../FavouritesPopover';
import LayoutMain from '../LayoutMain';
import CarInfoPrice from '../CarInfoPrice';
import CarDisclaimer from '../CarDisclaimer';
import CarDealerLink from '../CarDealerLink';

import style from './style.module.scss';

const Car: FC = () => {
  const store = useStore();

  useCarModels();
  useDealerOffices();

  const { CarTitle, CarInfoBook } = outsideComponents;
  const dispatch = useDispatch();
  const [visibleFavourPopover, setVisibleFavourPopover] = useState(false);

  const { id } = useParams<any>();
  const car = useSelectorConnect(selectors.getCar);
  const featureFlagCarsSeen = config.get('featureFlagCarsSeen');
  const dealerId = config.get('dealer');

  const carPriceInfo = getCarPriceInfo(car);
  const carPriceWithDiscounts = useSelector((state: State) =>
    getCarPriceWithDiscounts(state, car)
  );

  const favouritesEntities = useSelectorConnect(
    selectors.favourites.favouritesEntities
  );

  const favourVisibleChangeHandler = useCallback((visible: boolean) => {
    if (!visible) {
      setVisibleFavourPopover(visible);
    }
  }, []);

  const carModelImage = getCarModelImage(store.getState(), car);
  // в представительском режиме выводим img у модели
  const resolvedCarImages = !dealerId ? [carModelImage] : car?.images;

  useEffect(() => {
    if (car) {
      setVisibleFavourPopover(Boolean(favouritesEntities[car.id]));
    }
  }, [favouritesEntities]);

  useEffect(() => {
    if (id) {
      dispatch(actions.getCarFromStoreOrApi(id));
      window.scrollTo(0, 0);
    }

    return () => {
      dispatch(actions.clearCar());
    };
  }, [id]);

  React.useEffect(() => {
    if (car) {
      config.set('car', car);
    }
  }, [car]);

  const fields = [
    'class',
    'use',
    'floor_level',
    'engine_type',
    'seatbelts',
    'places',
    'length',
  ].map(field => ({
    source: 'specification',
    id: field,
  }));

  return car ? (
    <CarContext.Provider value={{ carPriceInfo, carPriceWithDiscounts }}>
      <LayoutMain>
        <CarBar />
        {CarTitle && <CarTitle car={car} />}
        <CarCard>
          <div className="col-lg-7">
            <CarGallery
              images={resolvedCarImages}
              imageDefault={carModelImage}
              presets={{
                gallery: {
                  id: '4',
                },
                'gallery-full': {
                  id: '2',
                },
                'gallery-thumbnail': {
                  id: '5',
                },
              }}
            />
          </div>
          <div className="col-lg-5">
            <CarInfo
              top={
                <>
                  {config.get('featureFlagFavourites') && (
                    <FavouritesPopover
                      visible={visibleFavourPopover}
                      onVisibleChange={favourVisibleChangeHandler}
                      content={
                        <Link to="/favourites">Перейти в Избранное</Link>
                      }
                    >
                      <div className={style.btnFavouriteWrap}>
                        <FavouriteBtn
                          className={style.btnFavourite}
                          car={car}
                          simpleBtn
                        />
                      </div>
                    </FavouritesPopover>
                  )}
                  <div className={style.btnWrapper}>
                    {dealerId ? (
                      <CarInfoFinance>
                        {/* TODO: Временно скрыто https://git.perx.ru/azgaz/rusbus-ring/-/issues/1818 */}
                        {/* <CarInfoPrice car={car} /> */}
                        <CarDealerLink
                          url={`${car.dealer.url}${config.get(
                            'bookUrl'
                          )}?model=${car.name}`}
                          buttonText="Цена по запросу"
                        />
                      </CarInfoFinance>
                    ) : (
                      <div className="mb-3">
                        <CarDealerLink url={car.dealer.url} />
                      </div>
                    )}
                    {CarInfoBook && <CarInfoBook car={car} />}
                  </div>
                </>
              }
              bottom={
                <>
                  <CarInfoProperties fields={fields} car={car} />
                  {/* <ColorOption big /> may be here */}
                </>
              }
            />
          </div>
        </CarCard>
        <CarOptions
          userMetadata={car.user_metadata}
          title="Дополнительные технические характеристики"
        />
        <CarComment specification={car.specification} />
        {config.get('featureFlagCarBook') && <CarForms />}
        <CarsRelated />
        {featureFlagCarsSeen && <CarsSeen car={car} />}
        <CarFooter>
          <CarDisclaimer car={car} />
          <CarBackButton />
        </CarFooter>
      </LayoutMain>
    </CarContext.Provider>
  ) : null;
};

export default Car;
