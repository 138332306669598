import { themeContext } from '@perxteam/azgaz-ui';
import appCreator from '../appCreator/components/App';
import { CarsFilters, CarTitle, CarInfoBook } from './components';
import filtersConfig from './filtersConfig';

export default appCreator({
  filtersConfig,
  azgazUiTheme: themeContext.rusbusTheme,
  components: {
    CarsFilters,
    CarTitle,
    CarInfoBook,
  },
});
