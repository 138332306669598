export default {
  environment: ENV.environment,
  validateConfig: ['dealer'],
  bookUrl: ENV.bookUrl,
  carstockApi: ENV.carstockApi,
  formApi: ENV.formApi,

  // Фича флаги в формате ENV.flagName === 'on':
  featureFlagCarBook: true,
  featureFlagCarsRelated: ENV.featureFlagCarsRelated === 'on',
  featureFlagCarsSeen: ENV.featureFlagCarsSeen === 'on',
  featureFlagFavourites: ENV.featureFlagFavourites === 'on',
};
