import React, { FC } from 'react';

import { useCarModels, useDealerOffices } from '@/hooks';
import CarsList from '../CarsList';
import CarsBar from '../CarsBar';
import CarsLoadmore from '../CarsLoadmore';
import LayoutMain from '../LayoutMain';
import outsideComponents from '../../outsideComponents';

const Cars: FC = () => {
  useCarModels();
  useDealerOffices();
  
  const { CarsFilters } = outsideComponents;

  return (
    <LayoutMain>
      {CarsFilters && <CarsFilters />}
      <CarsBar />
      <CarsList />
      <CarsLoadmore />
    </LayoutMain>
  );
};

export default Cars;
