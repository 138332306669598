/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect } from 'react';

import {
  Select,
  Option,
  RangeSlider,
  CarsFilters as AuiCarsFilters,
} from '@perxteam/azgaz-ui';

import {
  useSelector,
  selectors,
  utils,
  useDispatch,
  Ranges,
} from 'azgaz-connect';

import { config } from '@/utils';
import filtersConfig from '../../filtersConfig';

type CarsFiltersProps = {
  form: any;
  ranges: Ranges;
};

const CarsFilters: FC<CarsFiltersProps> = ({ form, ranges }) => {
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFiltersValues);
  const cars = useSelector(selectors.getCars);
  const isFiltersLoading = useSelector(selectors.getFiltersLoading);
  const { year: yearRange, price: priceRange } = ranges || {};

  const uiHelper = new utils.UiHelper({
    filters,
    form,
    dispatch,
  });

  // Сбрасываем фильтры при первом отображении
  useEffect(() => {
    if (!cars) {
      uiHelper.initFilters();
    }
  }, []);

  const [
    onClassChange,
    onUseChange,
    onFloorLevelChange,
    onEngineTypeChange,
    onGroupChange,
  ] = uiHelper.createOnChange([
    filtersConfig.class,
    filtersConfig.use,
    filtersConfig.floorLevel,
    filtersConfig.engineType,
    filtersConfig.group,
  ]);

  const [onYearChange, onPriceChange] = uiHelper.createDebounceOnChange([
    filtersConfig.year,
    filtersConfig.price,
  ]);

  const { getFieldProps } = form;

  return (
    <AuiCarsFilters
      title={config.get('carsTitle')}
      isLoading={isFiltersLoading}
      onFiltersReset={uiHelper.resetFilters}
    >
      {filters?.length && yearRange && (
        <div className="row">
          <div className="col-sm-6 col-md-3 mb-4">
            <Select
              title="Класс"
              placeholder="Все"
              allowClear
              optionLabelProp="children"
              showSearch={false}
              {...getFieldProps(filtersConfig.class.name, {
                onChange: onClassChange,
              })}
            >
              {uiHelper
                .getDefaultFilter(filtersConfig.class.path)
                .map(({ title, value }) => (
                  <Option key={value}>{title}</Option>
                ))}
            </Select>
          </div>
          <div className="col-sm-6 col-md-3 mb-4">
            <Select
              title="Назначение"
              placeholder="Все"
              allowClear
              optionLabelProp="children"
              showSearch={false}
              {...getFieldProps(filtersConfig.use.name, {
                onChange: onUseChange,
              })}
            >
              {uiHelper
                .getDefaultFilter(filtersConfig.use.path)
                .map(({ title, value }) => (
                  <Option key={value}>{title}</Option>
                ))}
            </Select>
          </div>
          <div className="col-sm-6 col-md-3 mb-4">
            <Select
              title="Уровень пола"
              placeholder="Все"
              allowClear
              optionLabelProp="children"
              showSearch={false}
              {...getFieldProps(filtersConfig.floorLevel.name, {
                onChange: onFloorLevelChange,
              })}
            >
              {uiHelper
                .getDefaultFilter(filtersConfig.floorLevel.path)
                .map(({ title, value }) => (
                  <Option key={value}>{title}</Option>
                ))}
            </Select>
          </div>
          <div className="col-sm-6 col-md-3 mb-4">
            <Select
              title="Тип двигателя"
              placeholder="Все"
              allowClear
              optionLabelProp="children"
              showSearch={false}
              {...getFieldProps(filtersConfig.engineType.name, {
                onChange: onEngineTypeChange,
              })}
            >
              {uiHelper
                .getDefaultFilter(filtersConfig.engineType.path)
                .map(({ title, value }) => (
                  <Option key={value}>{title}</Option>
                ))}
            </Select>
          </div>
          <div className="col-sm-6 col-md-3 mb-5 mb-sm-4">
            <RangeSlider
              title="Год выпуска"
              step={1}
              space={12}
              spade={8}
              {...yearRange}
              {...getFieldProps(filtersConfig.year.name, {
                onChange: onYearChange,
                initialValue: [yearRange.min, yearRange.max],
              })}
            />
          </div>
          <div className="col-sm-6 col-md-3 mb-4">
            <Select
              title="Тип"
              placeholder="Все"
              allowClear
              optionLabelProp="children"
              showSearch={false}
              {...getFieldProps(filtersConfig.group.name, {
                onChange: onGroupChange,
              })}
            >
              {uiHelper
                .getDefaultFilter(filtersConfig.group.path, {
                  makeTitle: (value: any) => {
                    const map: { [key: string]: string } = {
                      new: 'Новые',
                      used: 'Поддержанные',
                    };

                    return utils.UiHelper.defaultFilterMakeTitle(
                      map[value.id],
                      value.count
                    );
                  },
                })
                .map(({ title, value }) => (
                  <Option key={value}>{title}</Option>
                ))}
            </Select>
          </div>
          {/* TODO: Временно скрыто https://git.perx.ru/azgaz/rusbus-ring/-/issues/1818 */}
          {/* <div className="col-sm-12 col-md-6 mb-5 mb-md-4">
            <RangeSlider
              title="Стоимость"
              step={1000}
              space={12}
              spade={8}
              {...priceRange}
              {...getFieldProps(filtersConfig.price.name, {
                onChange: onPriceChange,
                initialValue: [priceRange.min, priceRange.max],
              })}
            />
          </div> */}
        </div>
      )}
    </AuiCarsFilters>
  );
};

export default utils.withForm(CarsFilters);
