import { Car } from 'azgaz-connect';
import { getSpecificationsForGroup } from '@perxteam/azgaz-ui';

/**
 * В блоке "Вас могут заинтересовать" должны выводиться похожие автомобили по критериям ниже:
 * Критерий вывода похожих автобусов:
 * Ориентируемся на бренд (ПАЗ для ПАЗ, ЛИАЗ для ЛИАЗ, КАВЗ дли КАВЗ)
 * и назначение (Коммерческие, Городские и пригородные, Междугородные и туристические, Специального назначения).
 * К примеру, для ПАЗ с назначением Коммерческие выводим только такие же Коммерческие ПАЗ.
 */

export default (car: Car) => {
  const specifications = getSpecificationsForGroup(car.specification, 'main', [
    'use',
  ]);

  return { 'specification.use': specifications.use };
};
