interface DataLayerItem {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}

interface DataLayer extends Window {
  dataLayer: DataLayerItem[];
}

declare const window: DataLayer;

/**
 * Возвращает объект DataLayer
 * @returns window.dataLayer
 */
const getDataLayer = () => {
  if (window && !window.dataLayer) {
    window.dataLayer = [];
  }

  return window.dataLayer || [];
};

const createDataLayerObj = (
  eventCategory: string,
  eventAction: string,
  eventLabel: string
): DataLayerItem => {
  return {
    event: 'GAEvent',
    eventCategory,
    eventAction,
    eventLabel,
  };
};

const LABEL_PREFIX = {
  bookCompany: 'reserve-phys',
  bookPersonal: 'reserve-jur',
};

const FORM_NAME_PREFIX = {
  bookCompany: 'Забронировать - юр',
  bookPersonal: 'Забронировать - физ',
};

// формируем eventLabel
const prepareAnalyticsData = (
  labelPrefix: string,
  formId: string,
  url: string,
  model: string
) => {
  let label = labelPrefix;
  if (formId) {
    label += `_${formId}`;
  }

  if (url) {
    label += `_${url}`;
  }

  if (model) {
    label += `_${model}`;
  }

  return label;
};

/**
 * Записывает событие в DataLayer
 * @param obj - объект отправляемого события
 * @example
 * const obj = {
 *   event: 'UA event',
 *   eventCategory: 'Сontact',
 *   eventAction: 'Form view',
 *   eventLabel: 'Configuration',
 * }
 *
 * pushDataLayer(obj);
 */

const pushDataLayer = (obj: DataLayerItem) => {
  getDataLayer().push(obj);
};

const sendAnalytics = (
  eventCategory: string,
  eventAction: string,
  eventLabel: string
) => {
  const object = createDataLayerObj(eventCategory, eventAction, eventLabel);

  if (object) {
    pushDataLayer(object);
  }
};

const dataLayerEvents = {
  getRoot: function() {
    return (window as any).rusBusApp?.dataLayer;
  },
  fire: {
    visit: function(formId: string, formName: string) {
      try {
        const root = dataLayerEvents.getRoot();

        if (root) {
          root.actions.sendVisitEvent(formId, formName);
        }
      } catch (err) {
        console.warn(err);
      }
    },
    filling: function(formId: string, formName: string, data?: any) {
      try {
        const root = dataLayerEvents.getRoot();

        if (root) {
          root.actions.sendFillingEvent(formId, formName, true, data);
        }
      } catch (err) {
        console.warn(err);
      }
    },
    submit: function(formId: string, data: any, formName: string) {
      try {
        const root = dataLayerEvents.getRoot();

        if (root) {
          root.actions.sendSubmitEvent(formId, data, formName);
        }
      } catch (err) {
        console.warn(err);
      }
    },
    click: function(category: string, action: string, label: string) {
      try {
        const root = dataLayerEvents.getRoot();

        if (root) {
          root.actions.sendAnalytics(category, action, label);
        }
      } catch (err) {
        console.warn(err);
      }
    },
  },
};

export {
  prepareAnalyticsData,
  sendAnalytics,
  LABEL_PREFIX,
  FORM_NAME_PREFIX,
  dataLayerEvents,
};

export default getDataLayer;
