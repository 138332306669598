/* eslint-disable react/jsx-indent */
import React, { FC } from 'react';
import { Car } from 'azgaz-connect';
import { getCarPriceInfo } from '../../selectors';
import style from './style.module.scss';

type CarDisclaimerProps = {
  car: Car | undefined;
};

const CarDisclaimer: FC<CarDisclaimerProps> = ({ car }) => {
  const carPriceInfo = getCarPriceInfo(car);

  const tradeInDisclaimer = carPriceInfo.disclaimers.tradeInDisclaimer?.trim();
  const creditDisclaimer = carPriceInfo.disclaimers.creditDisclaimer?.trim();
  const leasingDisclaimer = carPriceInfo.disclaimers.leasingDisclaimer?.trim();

  return (
    <ul className={style.list}>
      {[
        ...(carPriceInfo.tradeIn && tradeInDisclaimer
          ? [
              <>
                <sup>{carPriceInfo.disclaimers.order.indexOf('tradeIn')}</sup>{' '}
                {tradeInDisclaimer}
              </>,
            ]
          : []),
        ...(carPriceInfo.credit && creditDisclaimer
          ? [
              <>
                <sup>{carPriceInfo.disclaimers.order.indexOf('credit')}</sup>{' '}
                {creditDisclaimer}
              </>,
            ]
          : []),
        ...(carPriceInfo.leasing && leasingDisclaimer
          ? [
              <>
                <sup>{carPriceInfo.disclaimers.order.indexOf('leasing')}</sup>{' '}
                {leasingDisclaimer}
              </>,
            ]
          : []),
      ].map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={style.item} key={index}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default CarDisclaimer;
