/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formServiceRequest } from '@/api';
import { Dispatch } from 'redux';
import { getStore as getStoreConnect } from 'azgaz-connect';
import { config, resolveFsFields, applyOnSubmitHandler } from '@/utils';
import { userTypes } from '@/constants';
import { getBookFsData } from '../../selectors';
import { GetState } from '../index';
import { TFormFields } from '@/utils/resolveFsFields';

export type TUserTypes = keyof typeof userTypes;

export type TField<T> = { value: T };

export const useCases = {
  createOrder: 'createOrder',
} as const;

export const fields = {
  userType: 'client_type',
  userName: 'userName',
  userSurname: 'userSurname',
  userPhone: 'client_phone_number',
  userEmail: 'client_email',
  userComment: 'commentary',
  companyName: 'client_company_name',
  dealerOffice: 'dealerOffice',
  termPersonalData: 'termPersonalData',
  termGettingInfo: 'agreement_mailing',
} as const;

export type TFields = {
  [fields.userType]: TField<TUserTypes>; // тип лица оформляющего заявку
  [fields.userSurname]: TField<string>; // Фамилия
  [fields.userName]: TField<string>; // Имя
  [fields.userPhone]: TField<string>; // Телефон пользователя
  [fields.userEmail]: TField<string>;
  [fields.userComment]: TField<string>;
  [fields.companyName]: TField<string>; // Название организации
  [fields.termPersonalData]: TField<boolean>; // Согласие на обработку персональных данных
  [fields.termGettingInfo]: TField<boolean>; // Согласие на получение информации
};

type SliceStateOrder = {
  formSuccessfullySent: boolean;
  fields: TFields;
  loading: {
    fs: boolean;
  };
  error: {
    fs?: string;
  };
};

const initialState: SliceStateOrder = {
  formSuccessfullySent: false,
  fields: {
    [fields.userType]: {
      value: userTypes.company,
    },
    [fields.userSurname]: {
      value: '',
    },
    [fields.userName]: {
      value: '',
    },
    [fields.userPhone]: {
      value: '',
    },
    [fields.userEmail]: {
      value: '',
    },
    [fields.userComment]: {
      value: '',
    },
    [fields.companyName]: {
      value: '',
    },
    [fields.termPersonalData]: {
      value: true,
    },
    [fields.termGettingInfo]: {
      value: false,
    },
  },
  loading: {
    fs: false,
  },
  error: {},
};

const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    setFields(
      state,
      action: PayloadAction<{ [K in keyof Partial<TFields>]: TFields[K] }>
    ) {
      state.fields = {
        ...state.fields,
        ...action.payload,
      };
    },
    fsRequest(state) {
      state.loading.fs = true;
      state.error.fs = undefined;
      state.formSuccessfullySent = false;
    },
    fsSuccess(state) {
      state.loading.fs = false;
      state.formSuccessfullySent = true;
    },
    fsError(state) {
      state.error.fs = 'К сожалению, отправка данных не удалась';
      state.loading.fs = false;
    },
  },
});

const submitBook = (callback?: (data: TFormFields) => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    let fsData = resolveFsFields(
      getBookFsData(getState(), getStoreConnect().getState())
    );

    fsData = applyOnSubmitHandler(fsData, config.get('formOnSubmitBook'));

    callback?.(fsData);

    dispatch(bookSlice.actions.fsRequest());
    await formServiceRequest(config.get('formIdBook'), fsData);
    dispatch(bookSlice.actions.fsSuccess());
  } catch (e) {
    dispatch(bookSlice.actions.fsError());
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const bookActions = {
  ...bookSlice.actions,
  submitBook,
};

export default bookSlice;
