import { utils } from 'azgaz-connect';
import { config } from '@/utils';

const filtersConfig = utils.UiHelper.patchFiltersConfig({
  class: {
    id: 'class',
    path: 'specification.class',
  },
  year: {
    id: 'year',
    path: 'year',
  },
  use: {
    id: 'use',
    path: 'specification.use',
  },
  floorLevel: {
    id: 'floor_level',
    path: 'specification.floor_level',
  },
  engineType: {
    id: 'engine_type',
    path: 'specification.engine_type',
  },
  group: {
    id: 'group',
    path: 'group',
    prepare: (value: string) => [value],
    defaultVal: ['new', 'used'],
  },
  price: {
    id: 'price',
    path: 'price.value.0.0',
    defaultVal: [1, null],
  },
  state: {
    id: 'state',
    defaultVal: 'active',
  },
  priceTier: {
    id: 'price_tier',
    defaultVal: 1,
  },
  discountTier: {
    id: 'discount_tier',
    defaultVal: 0,
  },
  dealerIn: {
    id: 'dealer__in',
    defaultVal: [config.get('dealer')],
  },
});

export default filtersConfig;
