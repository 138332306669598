import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { themeContext } from '@perxteam/azgaz-ui';
import createCarstockProvider, { utils } from 'azgaz-connect';
import { config, generateReqParamsCarsRelated } from '@/utils';
import store from '../../store';
import outsideComponents from '../../outsideComponents';
import Cars from '../Cars';
import Car from '../Car';
import Favourites from '../Favourites';

const browserHistory = createBrowserHistory({
  basename: config.get('basename'),
});
const featureFlagCarsRelated = config.get('featureFlagCarsRelated');
const carsRelated = config.get('carsRelated') || {};
carsRelated.getReqParams = generateReqParamsCarsRelated;

const carsSeen = config.get('carsSeen');

const { ThemeProvider } = themeContext;

type AppCreatorProps = {
  filtersConfig: utils.PatchedFiltersConfig;
  azgazUiTheme: themeContext.ThemeType;
  components: typeof outsideComponents;
};

function appCreator({
  filtersConfig,
  azgazUiTheme,
  components,
}: AppCreatorProps) {
  const CarstockProvider = createCarstockProvider({
    carStockApi: ENV.carstockApi,
    filtersConfig,
    thumbId: 0,
    history: browserHistory,
    carsSeen,
    ...(featureFlagCarsRelated && { carsRelated }),
  });

  Object.assign(outsideComponents, components);

  const App: FC = () => {
    return (
      <Provider store={store}>
        <ThemeProvider value={azgazUiTheme}>
          <CarstockProvider>
            <Router history={browserHistory}>
              <Switch>
                <Route path="/favourites">
                  <Favourites />
                </Route>
                <Route path="/:id">
                  <Car />
                </Route>
                <Route path="/">
                  <Cars />
                </Route>
              </Switch>
            </Router>
          </CarstockProvider>
        </ThemeProvider>
      </Provider>
    );
  };

  return App;
}

export default appCreator;
