/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';

import { config } from '@/utils';

type dataProps = {
  [key: string]: string;
};

const formServiceApi = config.get('formApi');

const api = axios.create({ baseURL: formServiceApi });

export default (formId: string, data: dataProps) => {
  return api.post(`data/${formId}`, {
    ...data,
  });
};

export const getDataSource = (officesDatasourceId: string) => {
  return api.get(`datasource/${officesDatasourceId}/feed`);
};
