import React, { useCallback } from 'react';
import SmoothScroll from 'smooth-scroll';

import { Button } from '@perxteam/azgaz-ui';
import { Components } from '@/apps/appCreator/outsideComponents';
import { dataLayerEvents } from '@/utils/dataLayer';

const smoothScroll = new SmoothScroll(undefined, {
  updateURL: false,
});
const eventScrollToForms = new CustomEvent('scrollToForms');

const CarInfoBook: Components['CarInfoBook'] = ({ car }) => {
  const scrollToForms = useCallback(() => {
    dataLayerEvents.fire.click('button', 'click', `reserve_${car.model}`);

    smoothScroll.animateScroll(document.getElementById('forms'));
    // scrollStart & scrollEnd не срабатывают если отключена анимация в OS
    document.dispatchEvent(eventScrollToForms);
  }, []);
  return <Button onClick={scrollToForms}>Забронировать</Button>;
};
export default CarInfoBook;
