import React, { FC } from 'react';
import { useSelector, selectors, useDispatch, utils } from 'azgaz-connect';
import { CarsList as AuiCarsList } from '@perxteam/azgaz-ui';
import CarsItem from '../CarsItem';

const CarsList: FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFiltersValues);
  const cars = useSelector(selectors.getCars);
  const { isZeroPage } = useSelector(selectors.getIsPageLoading);
  const changedRangeFilters = useSelector(selectors.getChangedRangesFilters);

  const uiHelper = new utils.UiHelper({
    filters,
    dispatch,
  });

  const carsEmptyText = changedRangeFilters.length ? (
    ''
  ) : (
    <p>К сожалению, не найдено ни одного автомобиля в наличии</p>
  );

  return (
    <AuiCarsList
      cars={cars}
      carsEmptyText={carsEmptyText}
      isZeroPageLoading={isZeroPage}
      clearFilters={() => uiHelper.resetFilters()}
      Item={CarsItem}
    />
  );
};

export default CarsList;
