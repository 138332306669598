import React, { FC, ReactNode } from 'react';
import { Checkbox, Field } from '@perxteam/azgaz-ui';
import style from './style.module.scss';

type TermCheckboxProps = {
  name: string;
  title: ReactNode;
  toogleTitle: ReactNode;
  form: any;
  onToogleClick: () => void;
  term?: ReactNode;
  termVisibility?: boolean;
  error?: ReactNode;
  rules?: any[];
};

const TermCheckbox: FC<TermCheckboxProps> = ({
  error,
  form,
  name,
  rules,
  termVisibility,
  onToogleClick,
  term,
  title,
  toogleTitle,
}) => {
  return (
    <Field error={error}>
      {form.getFieldDecorator(name, {
        rules,
      })(<Checkbox className={style.term}>{title}</Checkbox>)}{' '}
      <button type="button" className={style.termLink} onClick={onToogleClick}>
        {toogleTitle}
      </button>
      {termVisibility && term && <div className={style.termText}>{term}</div>}
    </Field>
  );
};

export default TermCheckbox;
