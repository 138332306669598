import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchDealerOffices } from '@/apps/appCreator/store/dealerOffices';
import { getDealerOffices } from '@/apps/appCreator/selectors';
import { config } from '@/utils';

export default () => {
  const dispatch = useDispatch();
  const { dealerOffices } = useSelector(getDealerOffices);

  useEffect(() => {
    const officesDatasourceId = config.get('officesDatasourceId');
  
    if (!officesDatasourceId) return;

    if (dealerOffices.length === 0) {
      dispatch(fetchDealerOffices(officesDatasourceId));
    }
  }, []);
};
