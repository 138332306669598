import React, { FC } from 'react';

import { hooks, Car } from 'azgaz-connect';
import { ExtraCarsList } from '@perxteam/azgaz-ui';
import CarsItem from '@/apps/appCreator/components/CarsItem';

type CarsSeenProps = {
  car: Car;
};

const CarsSeen: FC<CarsSeenProps> = ({ car }) => {
  const { carsSeenForCurrentCar } = hooks.useSeens(car);

  return (
    <ExtraCarsList
      className="carsSeen"
      title="Вы недавно смотрели"
      Item={CarsItem}
      cars={carsSeenForCurrentCar}
    />
  );
};

export default CarsSeen;
