import React, { FC } from 'react';

import { Button } from '@perxteam/azgaz-ui';

import style from './style.module.scss';

type TProps = {
  buttonText?: string;
  url?: string;
};

const CarDealerLink: FC<TProps> = ({
  url,
  buttonText = `Уточняйте стоимость у дилера`,
}) => {
  const stopEvent = (e: React.MouseEvent) => e.stopPropagation();

  if (!url) {
    return null;
  }

  return (
    <Button
      className={style.btn}
      href={url}
      target="_blank"
      transparent
      onClick={stopEvent}
    >
      {buttonText}
    </Button>
  );
};

export default CarDealerLink;
