export default function() {
  let baseName = '/';

  ['/stock/'].forEach(item => {
    if (window.location.pathname.includes(item)) {
      baseName = item;
    }
  });

  return baseName;
}
