import { connect } from 'react-redux';
import { config, connectForm } from '@/utils';
import CarBook, { getFormNameForEvent } from './CarBook';
import { State } from '../../store';
import { bookActions } from '../../store/book';
import { dataLayerEvents } from '@/utils/dataLayer';

export default connect((state: State) => {
  return {
    formState: state.book.fields,
  };
})(
  connectForm(
    'formState',
    bookActions.setFields,
    CarBook,
    (props: any, TFields) => {
      const formName = getFormNameForEvent(props.form.getFieldValue);

      dataLayerEvents.fire.filling(config.get('formIdBook'), formName, {
        car_model: config.get('car')?.model,
      });
    }
  )
);
