import React, { FC, useEffect } from 'react';
import cn from 'classnames';

import { useCollapses } from '@/hooks';

import CarBook from '../CarBook';
import style from './style.module.scss';

const CarForms: FC = () => {
  const [termFormCollapse] = useCollapses([false]);

  // Автоматическое открытие вкладки "Забронировать"
  useEffect(() => {
    const scrollToFormsHandler = (e: any) => {
      if (e.type === 'scrollToForms') {
        // показываем формы, по умолчанию формы скрыты
        termFormCollapse.setVisibility(true);
      }
    };

    document.addEventListener('scrollToForms', scrollToFormsHandler, false);

    return () => {
      document.removeEventListener('scrollToForms', scrollToFormsHandler);
    };
  }, []);

  return (
    <div className="azgazui__forms py-5" id="forms">
      <div className="container">
        <div className={style.tabTitles}>
          <div className="row justify-content-between align-items-center">
            <div className="col-md-7">
              <button
                type="button"
                className={style.bookTabTitle}
                onClick={termFormCollapse.toggle}
              >
                Забронировать
              </button>
            </div>
            <div className="col-auto">
              <button
                type="button"
                className={cn(style.btnToggle, {
                  open: termFormCollapse.visibility,
                })}
                onClick={termFormCollapse.toggle}
              >
                {termFormCollapse.visibility ? 'свернуть' : 'развернуть'}
              </button>
            </div>
          </div>
        </div>
        <div
          className={cn(style.tabContents, {
            show: termFormCollapse.visibility,
          })}
        >
          <CarBook />
        </div>
      </div>
    </div>
  );
};

export default CarForms;
