import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@perxteam/azgaz-ui';

const BackButton: FC = () => {
  const history = useHistory();

  const onClick = () => history.push('/');

  return (
    <Button onClick={onClick} transparent>
      назад к списку
    </Button>
  );
};

export default BackButton;
