/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getCarModels } from '@/api';

export const fetchCarModels = createAsyncThunk(
  'carModels/fetchCarModels',
  async () => {
    const response = await getCarModels();
    return response.data;
  }
);

export type TModel = {
  id: string;
  images?: { id: string; type: string; url: string }[];
};

type SliceStateCarModels = {
  models: TModel[];
};

const initialState: SliceStateCarModels = {
  models: [],
};

const modelsSlice = createSlice({
  name: 'carModels',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCarModels.fulfilled, (state, action) => {
      state.models = action.payload;
    });
  },
});

export default modelsSlice;
