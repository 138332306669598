import React, { FC } from 'react';
import { useStore } from 'react-redux';

import {
  CarsItem as CarsItemWrap,
  CarsItemImg,
  CarsItemInfo,
  CarsItemOptions,
  CarsItemFinance,
  CarsItemPrice,
  CarsItemAssign,
  getSpecification,
} from '@perxteam/azgaz-ui';

import { Car, selectors } from 'azgaz-connect';
import { config } from '@/utils';
import { getCarModelImage } from '@/apps/appCreator/selectors';
import CarsItemFavouriteBtn from '../CarsItemFavouriteBtn';
import CarDealerLink from '../CarDealerLink';
import outsideComponents from '../../outsideComponents';

type CarsItemProps = {
  car: Car;
};

const CarsItem: FC<CarsItemProps> = ({ car }) => {
  const store = useStore();
  const { CarsItemDealer } = outsideComponents;
  const dealerId = config.get('dealer');
  const { id, name, specification, dealer } = car;

  // в представительском режиме выводим img у модели
  const carModelImage = getCarModelImage(store.getState(), car);
  const imgAttrs = selectors.getCarFirstThumb(car);
  const resolvedImageUrl = !dealerId ? carModelImage.url : imgAttrs.url;

  const assign = getSpecification(specification, 'main', 'use');

  const fields = ['length', 'seats', 'engine_type', 'seatbelts'].map(field => ({
    source: 'specification',
    id: field,
  }));

  return (
    <CarsItemWrap id={id}>
      <CarsItemImg src={resolvedImageUrl} alt={imgAttrs.alt} />
      <CarsItemInfo title={name}>
        {config.get('featureFlagFavourites') && (
          <CarsItemFavouriteBtn car={car} />
        )}
        <CarsItemAssign>{assign}</CarsItemAssign>
        <CarsItemOptions car={car} fields={fields} />

        {dealerId ? (
          <CarsItemFinance>
            {/* TODO: Временно скрыто https://git.perx.ru/azgaz/rusbus-ring/-/issues/1818 */}
            {/* <CarsItemPrice
              price={0}
              currency={dealer.currency}
            /> */}
            <CarDealerLink
              url={`${dealer.url}${config.get('bookUrl')}?model=${name}`}
              buttonText="Цена по запросу"
            />
          </CarsItemFinance>
        ) : (
          <CarDealerLink url={dealer.url} />
        )}

        {CarsItemDealer && <CarsItemDealer car={car} />}
      </CarsItemInfo>
    </CarsItemWrap>
  );
};

export default CarsItem;
