import React, { FC } from 'react';
import { useSelector, selectors, useDispatch, actions } from 'azgaz-connect';
import { CarsLoadmore as AuiCarsLoadmore } from '@perxteam/azgaz-ui';

const CarsLoadmore: FC = () => {
  const dispatch = useDispatch();
  const count = useSelector(selectors.getCarsCount);
  const isCarsLoading = useSelector(selectors.getCarsLoading);

  return (
    <AuiCarsLoadmore
      allUnloadCount={count.rest}
      isLoading={isCarsLoading}
      willLoadCount={count.nextPage}
      onClick={() => dispatch(actions.getNextPage())}
    />
  );
};

export default CarsLoadmore;
