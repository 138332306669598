/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getDataSource } from '@/api';

export const fetchDealerOffices = createAsyncThunk(
  'dealerOffices/fetchDealerOffices',
  async (officesDatasourceId: string) => {

    const response = await getDataSource(officesDatasourceId);
    return response.data;
  }
);

export type TDealerOffice = {
  id: string;
  name: string;
};

type SliceStateDealerOffices = {
  dealerOffices: TDealerOffice[];
};

const initialState: SliceStateDealerOffices = {
  dealerOffices: [],
};

const modelsSlice = createSlice({
  name: 'carModels',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDealerOffices.fulfilled, (state, action) => {
      state.dealerOffices = action.payload;
    });
  },
});

export default modelsSlice;
