import { createSelector } from '@reduxjs/toolkit';
import { fieldsToKeyValue } from '@/utils';
import { selectors as selectorsConnect, CarstockState } from 'azgaz-connect';
import {
  getSpecification,
  getSpecificationsForGroup,
  numberFormat,
} from '@perxteam/azgaz-ui';
import { TFields } from '../store/book';
import { State } from '../store';
import getBookFields from './getBookFields';
import getCarPriceInfo from './getCarPriceInfo';

/**
 * Данные по броне для отправки в сервис форм
 */
const getBookFsData = createSelector(
  getBookFields,
  (state: State, carstockState: CarstockState) => {
    const car = selectorsConnect.getCar(carstockState);
    const carPriceInfo = getCarPriceInfo(car);

    const dealerComment =
      car && getSpecification(car.specification, 'other', 'dealer_comment');

    const mainSpecification = getSpecificationsForGroup(
      car?.specification,
      'main',
      ['engine_type']
    );

    return (
      car && {
        dealerComment,
        carEngineType: mainSpecification.engine_type,
        carVin: car.vin,
        carName: car.name,
        car_model: car.model,
        carYear: car.year.toString(),
        carPrice: numberFormat()(carPriceInfo.price),
      }
    );
  },
  (fields, carInfo) => {
    const resolvedFormFields = fieldsToKeyValue<
      { [K in keyof TFields]: TFields[K]['value'] }
    >(fields);

    const fullData = { ...resolvedFormFields, ...carInfo };
    return fullData;
  }
);

export default getBookFsData;
