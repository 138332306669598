import React, { FC, MouseEvent } from 'react';
import AntdPopover from 'antd/lib/popover';
import cn from 'classnames';
import {
  actions as actionsConnect,
  selectors as selectorsConnect,
  useDispatch as useDispatchConnect,
  useSelector as useSelectorConnect,
  Car,
} from 'azgaz-connect';
import style from './style.module.scss';
import stylePopover from '../FavouritesPopover/style.module.scss';

type CarsItemFavouriteBtnProps = {
  car: Car;
  className?: string;
  simpleBtn?: boolean;
};

const CarsItemFavouriteBtn: FC<CarsItemFavouriteBtnProps> = ({
  car,
  className,
  simpleBtn,
}) => {
  const dispatchConnect = useDispatchConnect();

  const favouritesEntities = useSelectorConnect(
    selectorsConnect.favourites.favouritesEntities
  );

  const isCarFavourite = favouritesEntities[car.id];

  const textContent = isCarFavourite
    ? 'Удалить из избранного'
    : 'Добавить в избранное';

  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  const addToFavourites = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatchConnect(actionsConnect.favourites.switchFavourite(car));
  };

  return simpleBtn ? (
    <button
      type="button"
      className={cn(style.switch, className, {
        [style.active]: favouritesEntities[car.id],
      })}
      onClick={addToFavourites}
    >
      <span className={style.icon} />
    </button>
  ) : (
    <AntdPopover
      overlayClassName={cn(stylePopover.popover, {
        [stylePopover.popoverActive]: isCarFavourite,
      })}
      placement="top"
      trigger="hover"
      content={textContent}
    >
      <button
        type="button"
        className={cn(style.switch, className, {
          [style.active]: favouritesEntities[car.id],
        })}
        onClick={addToFavourites}
      >
        <span className={style.icon} />
      </button>
    </AntdPopover>
  );
};

export default CarsItemFavouriteBtn;
